<template>
  <div class="ddj-clickCount">
    <!-- <div class="main-Title bgff"><h2>点击数</h2></div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="list-filter mb10">
        <el-form :model="filter" label-width="80px" size="small">
          <el-row>
            <el-col :span="6" style="height: 34px">
              <el-form-item label="Date:" label-width="40px" class="mb5">
                <el-date-picker
                  v-model="filter.date"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="Start Date"
                  end-placeholder="End Date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  :clearable="false"
                  class="w100"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :xl="4">
              <el-form-item label="offer ID:" label-width="70px">
                <el-input v-model="filter.offerId" placeholder="Please enter offer ID"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :xl="4">
              <el-form-item label="Country:">
                <el-select v-model="filter.country" placeholder="Please select" class="w100">
                  <el-option label="全部" value="" />
                  <el-option
                    v-for="item in options.country"
                    :key="item.id"
                    :value="item.countryCode"
                    :label="item.countryCode"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!--            <el-col :xs="24" :sm="12" :md="6" :xl="3">-->
            <!--              <el-form-item label="OS:" label-width="50px">-->
            <!--                <el-select v-model="filter.os" placeholder="Please select OS" class="w100">-->
            <!--                  <el-option label="全部" value="" />-->
            <!--                  <el-option-->
            <!--                    v-for="item in options.os"-->
            <!--                    :key="item.value"-->
            <!--                    :value="item.value"-->
            <!--                    :label="item.label"-->
            <!--                  ></el-option>-->
            <!--                </el-select>-->
            <!--              </el-form-item>-->
            <!--            </el-col>-->
            <el-col :xs="24" :sm="12" :md="6" :xl="4">
              <el-form-item label="directLink:">
                <el-select v-model="filter.IsDirectLink" placeholder="Please select" class="w100">
                  <el-option label="全部" value="" />
                  <el-option label="yes" value="yes" />
                  <el-option label="no" value="no" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :xl="4">
              <el-form-item label="pid:">
                <el-input v-model="filter.pid" placeholder="Please enter pid"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :xl="4">
              <el-form-item label="pkg:">
                <el-input v-model="filter.pkg" placeholder="Please enter pkg"></el-input>
              </el-form-item>
            </el-col>
            <!--            <el-col :xs="24" :sm="12" :md="6" :xl="2">-->
            <!--              <el-form-item label-width="10px">-->
            <!--                <el-button-->
            <!--                  type="primary"-->
            <!--                  size="mini"-->
            <!--                  @click="getList('filter')"-->
            <!--                  :loading="loading.list"-->
            <!--                  >Search</el-button-->
            <!--                >-->
            <!--                <el-button type="primary" @click="downloadReport()">Download</el-button>-->
            <!--              </el-form-item>-->
            <!--            </el-col>-->
          </el-row>
          <el-row>
            <!--            <el-col :xs="24" :sm="12" :md="6" :xl="2">-->
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" align="right">
              <el-form-item label-width="10px">
                <el-button type="primary" @click="getList('filter')" :loading="loading.list"
                  >Search</el-button
                >
                <el-button type="primary" @click="downloadReport()">Download</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          :data="list"
          v-loading="loading.list"
          ref="listTableRef"
          class="w100"
          size="mini"
          border
          highlight-current-row
        >
          <el-table-column label="offer ID" prop="offerId"></el-table-column>
          <!--          <el-table-column label="OS" prop="os"></el-table-column>-->
          <el-table-column label="Country" prop="country"></el-table-column>
          <el-table-column label="isDirectLink" prop="isDirectLink"></el-table-column>
          <el-table-column label="pid" prop="pid"></el-table-column>
          <el-table-column label="pkg" prop="pkg"></el-table-column>
          <el-table-column label="成功点击" prop="sendSuccessCount"></el-table-column>
          <el-table-column label="成功展示" prop="impressionCount"></el-table-column>
          <el-table-column label="失败数" prop="sendFailCount"></el-table-column>
          <el-table-column label="时间" prop="createTime"></el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <div v-if="isShowPage" class="pagination-container pt-10" align="center">
          <el-pagination
            :page-size="pages.pageSize"
            :total="pagesTotal"
            background
            layout="total, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
  import ddjClickCountCon from '../../controllers/DDJ/v3/clickCount';
  export default {
    name: 'clickCount',
    ...ddjClickCountCon,
  };
</script>

<style></style>
